<template>
    <component :is="layout" />
</template>

<script>
import MainLayout from '@/shared/main-layout';
import EmptyLayout from '@/shared/empty-layout';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
    name: 'App',
    components: { MainLayout, EmptyLayout },
    data() {
        return {
            layout: null
        };
    },
    created() {
        // this.getAppState();
        window.removeEventListener('resize', this.onResizeWindow);
        window.addEventListener('resize', this.onResizeWindow);
        window.onbeforeunload = this.onBeforeUnload;
    },
    mounted() {
        this.checkReload();
        this.setMobile();
    },
    computed: {
        ...mapState('app', ['noNeedGetAppState'])
    },
    watch: {
        $route(to) {
            if (to.meta.layout !== undefined) {
                this.layout = to.meta.layout;
            } else {
                this.layout = 'MainLayout'; // this is default layout if route meta is not set
            }
            if (this.noNeedGetAppState) {
                this.setNoNeedGetAppState(false);
            } else {
                this.getAppState();
            }
        }
    },
    methods: {
        ...mapActions('app', ['getAppState']),
        ...mapMutations('app', ['setMobile', 'setNoNeedGetAppState']),
        onResizeWindow() {
            this.setMobile();
        },
        onBeforeUnload() {
            sessionStorage.setItem(
                'pathBeforeUnload',
                window.location.pathname
            );
        },
        checkReload() {
            if (
                window.location.pathname != '/preview' &&
                sessionStorage.getItem('pathBeforeUnload') !=
                    window.location.pathname &&
                (window.location.pathname == '/' ||
                    window.location.pathname == '/gs/gs0510v' ||
                    window.location.pathname == '/gm/gm0020i')
            ) {
                sessionStorage.removeItem('token');
                sessionStorage.removeItem('tokenTimeout');
                this.$router.push('/private/login');
            }
            sessionStorage.removeItem('pathBeforeUnload');
        }
    }
};
</script>

<style lang="scss">
@import 'bootstrap/scss/bootstrap.scss';
@import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';
@import '@/assets/scss/common.scss';
</style>
